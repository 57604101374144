<template>
    <div style="position:relative;"  class="crane-and-rigging">
        <div class="container landing-banner-container" style="padding-top: 7.5rem">
                <div class="left-banner">
                    <h1 style="font-size: 3.625rem;font-weight: bold;line-height: 1.21;color: #021222;padding-bottom: 1.875rem;">Build a Solid Foundation for <span style="color:#00c8d8;">Crane and Rigging</span> Services</h1>
                <div>
                 <p>                
                    For Crane and Rigging businesses with multiple contractors and equipment, scheduling and tracking can often be a complex task. Therefore, Record TIME offers an all-in-one solution to streamline this process, enhancing how your business operates with competitive advantages in the crane industry. 
                    <br><br>
                    Implementing Record TIME will revolutionise your <a href="https://recordtime.com.au/blog/business-management-solution">business management</a> practices. By automating manual processes, optimising how resources are allocated, and improving coordination, your crane and rigging business can become more productive. Consequently, this can help reduce administrative tasks and increase profits by refining your business processes.
                    <br><br>
                </p>
                </div>
                <h3>No More Paper Work and Real-Time Data Access</h3>
                <br><br>
                    <v-btn color="secondary" height="55" :width="$vuetify.breakpoint.xsOnly ? '' : '236'"
                        @click="redirectToLink('https://recordtime.com.au/blog/best-construction-scheduling-software')" class="mr-7 btn-1" elevation="0">
                        Read More
                    </v-btn>
            <v-btn height="56" class="get-demo-btn"  color="white" @click="getDemo()">
                Get A Demo Now
            </v-btn>
            </div>
            <div  class="right-banner" style="text-align: right;">
                <img :src="`${publicPath}assets/whoweserve/crane-and-rigging.webp`" alt="construction docket management software for pre start checklist, job scheduling and digital docketing features"  style="max-width: 90%;">
            </div>
            <div style="clear: both;"></div>
        </div>
        <v-container>
            <div class="gray-container">
                <h3>Optimise Equipment Utilisation, Enhance Dispatch Decision-Making and Eliminate the Use of Paper Documents.</h3>
                <img :src="`${publicPath}assets/whoweserve/construction-docket-management-software.webp`" alt="docket  management system" style="margin-top: 20px; object-fit: contain;width: 100%;">
                </div>
             <br><br>
            
            <div class="left-blog1">
                <h2> Crane Rigging Schedule and Checklist: Achieving Maximum Efficiency</h2><br/>
                <p>Crane and rigging require careful planning, coordination, and execution to ensure the safety of workers and the efficient completion of the task. Hence, our innovative construction software is here to ensure that all safety regulations and best practices are followed. In addition, it can provide checklists, safety guidelines, and real time monitoring of working operations. Also, it can alert operators and supervisors of potential safety risks, allowing for timely interventions and preventing accidents.
                    <br><br> Additionally, Record TIME's crane and rigging contractor software enables efficient planning and scheduling of lifting operations. It can help allocate the appropriate cranes and equipment for specific tasks, taking into account load requirements, crane capacities, and site conditions.
                </p>
                <p><b>Contact us today to learn more about how our construction software can benefit your <a href="https://recordtime.com.au/heavy-civil-software">heavy civil</a> construction company. Let us be your trusted partner in organising your operations and driving success.</b></p>
            </div>
            <div class="right-blog1 ">
                <img :src="`${publicPath}assets/whoweserve/crane-rigging.webp`" alt="crane and rigging scheduling with record time">
            </div>
            <div style="clear:both;"></div>
            <br>
            <h2>Documentation and Reporting</h2>
            <p>Rigging operations involve various documentation requirements. Firstly, there are lift plans, permits, and inspection reports. However, manual documentation can be time-consuming and prone to errors. Our crane and rigging contractor software can automate the creation and storage of such documents, ensuring accuracy and easy retrieval. Additionally, it can generate comprehensive reports on lift histories, maintenance records, and safety compliance. These reports provide valuable insights for project management and regulatory compliance, giving your crane operations a competitive edge.
            </p>
          </v-container>
          <get-demo-modal v-if="getDemoModal" :demoEmail="demoEmail" @excEmit="excEmit" @getDemoModalClose="getDemoModalClose" @demoResponse="demoResponse" url="https://www.youtube.com/embed/62kV7CIU_F4"/>

    </div>

</template>
<style lang="scss">
    .landing-banner-container{
        @media only screen and (max-width: 600px) {
            padding-top: 20px !important;
        }
    }
    .left-banner{
        width: 45%; 
        float: left;
        @media only screen and (max-width: 1280px) {
            width: 100%;
        }
    }
    .right-banner{
        width: 55%; 
        float: right;
        padding: 0px 20px 20px;
        @media only screen and (max-width: 1280px) {
            width: 100%;
        }
    }
    .landing-banner-container{
        @media only screen and (max-width: 600px) {
            padding-top: 20px !important;
            h1{ font-size: 40px !important;}
        }
    }
    .heavy-civil{
        .banner-img{
            @media only screen and (max-width: 600px) {
            position: relative !important;
            }

            @media only screen and (max-width: 1280px) {
                width: 100% !important;
                top: 20px !important;
                height: auto !important;
            }
        }
        p{
            @media only screen and (max-width: 1280px) {
                padding-bottom: 0px !important;
            }
        }
    }
    .banner-img{
        @media only screen and (max-width: 600px) {
            position: relative !important;
        }

        @media only screen and (max-width: 1280px) {
            position: relative !important;
        }
    }
    .gray-container{
        background: #F8F9FB;
        padding: 20px;
        margin-top: 30px;
        h3 {
            font-size: 32px;
            font-weight: bold;
            text-align: center;
            color: $black-pearl;
            margin-bottom: 15px;

            span {
            color: $faux-dark-turquoise;
            }
        }
        p{
            margin-top: 40px;
            text-align: center;
            color: #000;
        }
        .list{
            h1{
                color: #00C8D8;
            }
            p{
                color: #000;
                text-align: left;
            }
        }
    }
    .left-blog1{
      float:left;
      max-width: calc(100% - 600px);
      padding-right: 15px;
      @media only screen and (max-width: 1280px) {
        max-width: 100%;
      }
    }
    .right-blog1{
      width: 600px;
      float:right;
      @media only screen and (max-width: 1280px) {
        width: 100%;
      }
      img{
        max-width: 600px;
        width: 100%;
        @media only screen and (max-width: 1280px) {
            max-width: 100%;
        }
      }
    }
    @media only screen and (max-width: 600px) {
      .left-blog1{
        float: none;
        max-width: 100%;
        padding-right: 0px;
      }
      .right-blog1{
        float: none;
        max-width: 100%;
      }
    }
</style>
<script>
import GetDemoModal from '@/components/GetDemoModal.vue';
export default{
    metaInfo: {
    title: 'Crane and Rigging Contractor Management Software-Record TIME',
    // titleTemplate: 'Record TIME → %s',
    meta: [
      {name: 'name', content: ''},
      {name: 'description', content: 'Crane and rigging contractor software for scheduling, tracking, and replacing manual processes. Contractors can manage complex tasks and operations easily.'},
      {name: 'keyword', content: 'crane and rigging contractor software'}
      ],
    link: [
      { rel: 'canonical', href: 'https://recordtime.com.au/crane-and-rigging-contractor-software' }
    
    ]
  
  },
  components: {
        GetDemoModal
    },
    data() {
        return {
            publicPath: process.env.BASE_URL,
            getDemoModal: false,
        }
    },
    methods: {
        redirectToLink(url) {
        window.open(url, '_blank');
        },

        getDemoModalClose(){
            this.getDemoModal = false;
            this.demoEmail = '';
        },
        getDemo(){
            this.getDemoModal = true;
        }
    }
}
</script>
